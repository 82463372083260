/* Imports */
import { mapGetters } from '../helpers/mainHelpers';

export const useGenericAnalyticsDataHelper = () => {
  /* Store - Getters */
  const {
    getCustomerGroups: customerGroups,
  } = mapGetters();

  /**
   * Generates analytics data.
   *
   * @param {string} itemListId      - The ID of the item list.
   * @param {string} itemListName    - The name of the item list.
   * @returns {Object}               - The analytics data object.
   */
  const genericAnalyticsData = (itemListId, itemListName) => ({
    employee_flag: customerGroups?.value === 'US' ? 'TRUE' : 'FALSE',
    ...(itemListId !== undefined && { item_list_id: [itemListId] }),
    ...(itemListName !== undefined && {
      item_list_name: [`${window?.utag_data?.product_name[0]} - ${itemListName}`],
    }),
  });

  return { genericAnalyticsData };
};
