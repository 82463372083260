<script setup>
/* Props */
const props = defineProps({
  content: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <div
    id="js__product__availability"
    class="app__product__availability"
    v-html="props.content"
  />
</template>

<style lang="scss">
.app__product__availability {

  img {
    vertical-align: middle;
  }

  p {
    margin: 0;
  }
}
</style>
