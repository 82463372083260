<template>
  <div class="app__kicker__wrapper js__product__kicker">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.app__kicker__wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 4px;

  @include productBreakpoint('desktop-s') {
    justify-content: flex-start;
  }
}
</style>
