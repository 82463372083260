<script>
/* Imports */
import { mapActions, mapGetters } from 'vuex';

/* Analytics */
import {
  sendAnalyticsEvent,
  sanitizeAnalyticsString,
  getAnalyticsPriceData,
} from '../helpers/ga/analytics';

/* Components */
import AccessoriesSection from '../components/AccessoriesSection.vue';
import AccessoriesList from '../components/AccessoriesList.vue';

/* Composables */
import { useGenericAnalyticsDataHelper } from '../composables/useAnalyticsData';

export default {
  name: 'Accessories',
  components: {
    AccessoriesSection,
    AccessoriesList,
  },
  setup() {
    /* Initialize Composables */
    const { genericAnalyticsData } = useGenericAnalyticsDataHelper();
    return { genericAnalyticsData };
  },
  computed: {
    ...mapGetters({
      categoryNames: 'getCategoryNames',
      activeAccessories: 'getActiveAccessories',
      accessoriesByCategory: 'getActiveAccessoriesByCategory',
      sectionSelected: 'getActiveSection',
      viewAllAccessoriesText: 'getExpandSection',
      noAccessories: 'getTabNoAccessories',
      viewAllSections: 'getViewAllSections',
      sectionAccessoriesPricesLoaded: 'getSectionAccessoriesPricesLoaded',
    }),
    categoryNamesWithLimit() {
      // If section is already selected we hide the rest of the categories
      if (this.sectionSelected) {
        return [this.sectionSelected[0]];
      }

      return this.categoryNames;
    },
    noCategories() {
      return !this.sectionSelected && this.categoryNames.length === 0;
    },
    noAccessoriesInCategory() {
      return this.sectionSelected
      && this.accessoriesByCategory(this.sectionSelected).length === 0;
    },
  },
  watch: {
    sectionAccessoriesPricesLoaded(pricesLoaded) {
      if (pricesLoaded) {
        const accessories = this.sectionSelected ? this.accessoriesByCategory(this.sectionSelected) : this.activeAccessories;
        const category = this.sectionSelected ?? this.viewAllAccessoriesText;
        this.sendVILAccessoriesEvent(accessories, category);
      }
    },
    async categoryNames(newValue) {
      if (newValue?.length === 1) {
        await this.setActiveSection(newValue[0]);
      }
    },
  },
  methods: {
    ...mapActions(['setActiveSection']),
    sendVILAccessoriesEvent(accessories, selectedCategory) {
      const analyticsData = {
        ...this.genericAnalyticsData(
          'Accessories',
          selectedCategory,
        ),
        index: accessories?.map((_, index) => index + 1),
        product_id: accessories?.map((product) => product?.productId),
        product_brand: accessories?.map(() => window?.utag_data?.product_brand[0] || 'NA'),
        product_category: accessories?.map((product) => product?.categoryName || 'NA'),
        product_name: accessories?.map((product) => sanitizeAnalyticsString(product?.displayName) || 'NA'),
        product_price: accessories?.map((product) => getAnalyticsPriceData(product).price),
        product_original_price: accessories?.map((product) => getAnalyticsPriceData(product).listPrice),
        product_sku: accessories?.map((product) => product?.priceObj?.partNumber || 'NA'),
        product_url: accessories?.map((product) => product?.link || 'NA'),
        product_availability: accessories?.map((product) => getAnalyticsPriceData(product).availability),
        is_sale: accessories?.map((product) => getAnalyticsPriceData(product).isSale),
        price_shown: accessories?.map((product) => getAnalyticsPriceData(product).priceShown),
        tealium_event: 'view_item_list',
      };

      sendAnalyticsEvent(analyticsData);
    },
    sendSelectItemAccessoriesAnalyticsEvent(accessory, index) {
      const analyticsData = {
        ...this.genericAnalyticsData(
          'Accessories',
          accessory?.categoryName,
        ),
        index: index + 1 || 'NA',
        product_id: accessory?.productId || 'NA',
        product_brand: window?.utag_data?.product_brand[0] || 'NA',
        product_category: accessory?.categoryName || 'NA',
        product_variant: sanitizeAnalyticsString(accessory?.displayName) || 'NA',
        product_name: sanitizeAnalyticsString(accessory?.displayName) || 'NA',
        product_original_price: getAnalyticsPriceData(accessory).listPrice,
        product_price: getAnalyticsPriceData(accessory).price,
        product_sku: accessory?.partNumber || 'NA',
        product_url: accessory?.link || 'NA',
        product_availability: getAnalyticsPriceData(accessory).availability,
        is_sale: getAnalyticsPriceData(accessory).isSale,
        in_stock: getAnalyticsPriceData(accessory).inStock,
        price_shown: getAnalyticsPriceData(accessory).priceShown,
        tealium_event: 'select_item',
      };

      sendAnalyticsEvent(analyticsData);
    },
    handleAccessoryProductClick(accessory, index) {
      this.sendSelectItemAccessoriesAnalyticsEvent(accessory, index);
    },
  },
};
</script>

<template>
  <div class="app__product__accessories__outer-wrapper">
    <div v-if="!viewAllSections">
      <div
        v-if="!sectionSelected"
        class="app__product__accessories__wrapper app__product__accessories__wrapper--categories"
      >
        <AccessoriesSection
          v-for="categoryName in categoryNamesWithLimit"
          :key="categoryName"
          :category-name="categoryName"
          class="app__product__accessories__section"
        />
      </div>
      <div
        v-if="sectionSelected"
        class="app__product__accessories__wrapper"
      >
        <AccessoriesList
          v-for="(accessory, index) in accessoriesByCategory(sectionSelected)"
          :key="index"
          :accessory="accessory"
          @click="handleAccessoryProductClick(accessory, index)"
        />
      </div>
    </div>
    <div v-if="viewAllSections">
      <div
        class="app__product__accessories__wrapper"
      >
        <AccessoriesList
          v-for="(accessory, index) in activeAccessories"
          :key="index"
          :accessory="accessory"
        />
      </div>
    </div>
    <div
      v-if="noCategories"
      class="app__product__accessories__no__results"
    >
      {{ noAccessories }}
    </div>
    <div
      v-if="noAccessoriesInCategory"
      class="app__product__accessories__no__results"
    >
      {{ noAccessories }}
    </div>
  </div>
</template>

<style lang="scss">
.app__product__accessories {
  width: 100%;

  &__outer-wrapper {
    flex: 1;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    gap: 1.5em;
    margin-bottom: 2em;
    padding: 2em;

    @include breakpoint(sm) {
      padding: 0;
    }

    // Hide top level category cards on mobile

    &--categories {
      display: none;

      @include breakpoint(sm) {
        display: flex;
      }
    }
  }

  &__viewing {
    @include font-primary();
    text-align: center;
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  &__view-more {
    @include font-accent();
    text-align: center;
    @include font-primary-weight-bold();
    font-size: 1.2rem;
    margin: 1rem 0;
    cursor: pointer;
  }

  &__arrow {
    margin-left: 0.5rem;
    margin-bottom: -2px;
    height: 2rem;
    transform: rotate(90deg);
  }
}
</style>
