/**
 * @module helpers/affirm/supportedValues
 * @description Supported values for Affirm
 * @see {@link https://docs.affirm.com/developers/v1.0-Global-Integration/docs/global-affirm-integration-guide#supported-values|Affirm Supported Values}
 *
 * @constant {Array} AFFIRM_SUPPORTED_COUNTRY_CODES - Supported country codes for Affirm - for now only USA is supported in our implementation
 * @constant {Array} AFFIRM_SUPPORTED_LOCALES       - Supported locales for Affirm - for now only en_US is supported in our implementation
 */
export const AFFIRM_SUPPORTED_COUNTRY_CODES = ['USA'];
export const AFFIRM_SUPPORTED_LOCALES = ['en_US'];
