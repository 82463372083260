<script>
/* Components */
import ShippingDisclaimer from '../components/ShippingDisclaimer.vue';
import ProductLegalText from '../components/ProductLegalText.vue';
import ProductMarketingSummary from '../components/ProductMarketingSummary.vue';
import OldEmailSubscribe from './OldEmailSubscribe.vue';

export default {
  name: 'OldProductFootnote',
  components: {
    OldEmailSubscribe,
    ShippingDisclaimer,
    ProductLegalText,
    ProductMarketingSummary,
  },
  props: {
    shippingDisclaimerText: {
      type: String,
      default: '',
    },
    showShippingDisclaimer: {
      type: Boolean,
      default: false,
    },
    productHeaderDetails: {
      type: String,
      default: '',
    },
    showProductHeaderDetails: {
      type: Boolean,
      default: false,
    },
    productLegalText: {
      type: String,
      default: '',
    },
    showProductLegalText: {
      type: Boolean,
      default: false,
    },
    productMarketingSummary: {
      type: String,
      default: '',
    },
    showProductMarketingSummary: {
      type: Boolean,
      default: false,
    },
    partNumber: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div id="js__product__footnotes">
    <ShippingDisclaimer
      v-if="showShippingDisclaimer"
      :shipping-disclaimer-text="shippingDisclaimerText"
    />
    <OldEmailSubscribe
      v-if="showProductHeaderDetails"
      :key="partNumber"
      :product-header-details="productHeaderDetails"
    />
    <ProductLegalText
      v-if="showProductLegalText"
      :content="productLegalText"
    />
    <ProductMarketingSummary
      v-if="showProductMarketingSummary"
      :content="productMarketingSummary"
    />
  </div>
</template>
