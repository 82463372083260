<script setup>
/* Imports */
import { computed } from 'vue';

/* Helpers */
import { mapGetters, templateBuilder } from '../helpers/mainHelpers';

/* Composables */
import { useBackToTopBtn } from '../composables/useBackToTopBtn';

/* Components */
import TabLoader from '../components/TabLoader.vue';
import DevicesSection from '../components/DevicesSection.vue';
import BackToTopBtn from '../components/BackToTopBtn.vue';

/* Inject composables - BackToTop */
const { backToTopBtnRef, showBackToTopBtn } = useBackToTopBtn();

/* Store - Getters */
const {
  getTranslations: translations,
  getActiveCompatibleDevices: devicesData,
  getProductUrl: productUrlTemplate,
  getDevicesLoading: devicesLoading,
  getShowNoDevicesText: showNoDevicesText,
  getErrorDevicesText: errorDevicesText,
} = mapGetters();

/* Computed */
const devices = computed(() => devicesData.value.map((device) => ({
  ...device,
  link: templateBuilder(productUrlTemplate.value, { productId: device?.productId }),
})));

const getShowNoDevicesText = computed(() => showNoDevicesText.value && devices.value?.length === 0);
</script>

<template>
  <div
    ref="backToTopBtnRef"
    class="app__product__devices"
  >
    <tab-loader
      :show="devicesLoading"
    />
    <span v-if="getShowNoDevicesText">
      {{ translations?.devicesTabNoDevices }}
    </span>
    <span v-if="errorDevicesText">
      {{ translations?.devicesTabError }}
    </span>
    <div
      v-if="!devicesLoading"
      class="app__product__devices__container"
    >
      <DevicesSection
        v-for="(device, index) in devices"
        :key="device?.displayName"
        :device="device"
        :compatible-device-index="index"
        class="app__product__devices__section"
      />
    </div>
    <BackToTopBtn v-if="showBackToTopBtn" />
  </div>
</template>

<style lang="scss">
.app__product__devices {

  &__container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: flex-start;
    gap: 1.5em;
    margin: 1em 0;
  }
}
</style>
