<script>
/* Helpers */
import { cleanEscapedQuotes } from '../helpers/mainHelpers';

export default {
  name: 'PriceText',
  props: {
    formattedPrice: {
      type: String,
      default: '',
    },
  },
  computed: {
    priceHtml() {
      return this.formattedPrice ? cleanEscapedQuotes(this.formattedPrice) : '';
    },
  },
};
</script>

<template>
  <div
    class="app__product__price__formatted"
    data-test="app__product__price__formatted"
    v-html="priceHtml"
  />
</template>

<style lang="scss">
.app__product__price__formatted {

  .currency-sign {
    position: relative;
    font-size: 0.75em;
    top: -0.25em;
    display: inline;
    vertical-align: top;
  }

  .amount {
    font-size: 1em;
    display: inline;
    vertical-align: top;

    /* stylelint-disable-next-line */
    &+.currency-code {
      margin-left: 0.5em;
    }
  }

  .currency-code {
    font-size: 0.65em;
    margin-right: 0.35em;
    display: inline;
    vertical-align: top;
  }
}
</style>
