<script>
/* Imports */
import { mapGetters } from 'vuex';

/* Components */
import PriceText from './PriceText.vue';

export default {
  name: 'AccessoriesList',
  components: {
    PriceText,
  },
  props: {
    accessory: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      saveText: 'getPriceSaveText',
      vatToolTip: 'getVatToolTip',
      showVatToolTip: 'getShowVatToolTip',
    }),
  },
  methods: {
    getPriceObj(product) {
      return product?.priceObj;
    },
    getSavings(product) {
      return this.getPriceObj(product)?.savings;
    },
    getSalePrice(product) {
      return this.getPriceObj(product)?.salePrice;
    },
    getPrice(product) {
      return this.getPriceObj(product)?.listPrice;
    },
    getShowPrice(product) {
      return product?.showPrice && this.getPrice(product)?.price;
    },
    getFormattedSavings(product) {
      return this.getPriceObj(product)?.formattedHTMLSavings;
    },
    getFormattedSalePrice(product) {
      return this.getPriceObj(product)?.formattedHTMLSalePrice;
    },
    getFormattedPrice(product) {
      return this.getPriceObj(product)?.formattedHTMLPrice;
    },
    getPriceIsLoading(product) {
      const priceObj = this.getPriceObj(product);
      return !(priceObj && priceObj.priceLoaded);
    },
    showVatToolTipEl(product) {
      return this.showVatToolTip && this.getPrice(product);
    },
  },
};
</script>

<template>
  <div class="app__product__accessories__section">
    <div
      class="app__product__accessories__section__card"
      :data-testid="`accessory-card-${accessory?.productId}`"
    >
      <a
        :href="accessory.link"
        class="app__product__accessories__section__card__link"
      >
        <span
          class="app__product__accessories__section__card-content app__product__accessories__item"
        >
          <img
            :src="accessory.image"
            :alt="accessory.displayName"
            loading="lazy"
            width="130"
            height="130"
          >
          <g-heading
            class="app__product__accessories__section__card-content__subheading"
            heading-size="5"
            text-align="center"
            :content="accessory?.displayName"
          />
          <g-loader
            v-if="getPriceIsLoading(accessory)"
            type="default"
            class="app__product__accessories__section__pricing__loader"
          />
          <span
            v-if="getShowPrice(accessory)"
            class="app__product__accessories__section__pricing"
          >
            <span
              v-if="getSalePrice(accessory)"
            >
              <PriceText
                class="app__product__accessories__section__price--strike"
                :formatted-price="getFormattedPrice(accessory)"
              />
              <PriceText
                class="app__product__accessories__section__sale-price"
                :formatted-price="getFormattedSalePrice(accessory)"
              />
            </span>
            <PriceText
              v-else-if="getPrice(accessory)"
              class="app__product__accessories__section__price"
              :formatted-price="getFormattedPrice(accessory)"
            />
            <span
              v-if="getSavings(accessory)"
              class="app__product__accessories__section__savings"
            >
              <span
                class="kicker sec"
              >
                {{ saveText }} <PriceText :formatted-price="getFormattedSavings(accessory)" />
              </span>
            </span>
            <span
              v-if="showVatToolTipEl(accessory)"
              class="app__product__accessories__section__vat-tooltip"
              v-html="vatToolTip"
            />
          </span>
        </span>
      </a>
    </div>
  </div>
</template>

<style lang="scss">
.app__accessories__list {
  padding: 0;
  margin-bottom: 3em;

  li {
    list-style-type: none;
    padding: 0.5em 0;
  }
}
</style>
