<script>
/* Components */
import PriceText from '../components/PriceText.vue';

/* Old Components */
import OldSalesKicker from './OldSalesKicker.vue';

export default {
  name: 'OldPriceWrapper',
  components: {
    PriceText,
    OldSalesKicker,
  },
  props: {
    price: {
      type: String,
      default: '',
    },
    showPrice: {
      type: Boolean,
      default: false,
    },
    salePrice: {
      type: String,
      default: '',
    },
    showSalePrice: {
      type: Boolean,
      default: false,
    },
    savings: {
      type: String,
      default: '',
    },
    showSavings: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div
    v-if="showPrice"
    class="app__product__price__wrapper js__product__price"
  >
    <div v-if="showSalePrice">
      <PriceText
        id="js__product__price__discount"
        class="app__product__price__wrapper__main--discount"
        :formatted-price="price"
      />
      <PriceText
        id="js__product__price__main"
        class="app__product__price__wrapper__main"
        :formatted-price="salePrice"
      />
    </div>
    <div
      v-else
    >
      <PriceText
        id="js__product__price__main"
        class="app__product__price__wrapper__main"
        :formatted-price="price"
      />
    </div>
    <div
      v-if="showSavings"
    >
      <OldSalesKicker
        id="js__product__price__flag"
        :savings="savings"
        :save-text="translations"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app__product__price__wrapper {
  margin: 1em 0 2em;
  text-align: center;
  justify-content: center;

  @include productBreakpoint(desktop-s) {
    display: block;
    text-align: left;
    justify-content: left;
  }

  &__main {
    display: flex;
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.25rem;
    color: $blue;
    justify-content: center;

    @include productBreakpoint(desktop-s) {
      justify-content: flex-start;
    }

    &--discount {
      font-weight: 500;
      font-size: 0.75em;
      line-height: 1rem;
      margin: 0 0 0.5em 0;
      position: relative;
      display: inline-block;

      &::after {
        position: absolute;
        bottom: calc(50%);
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: $color-black;
      }
    }
  }
}
</style>
