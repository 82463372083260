<script setup>
/* Props */
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  tooltipText: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <div class="app__tooltip">
    <!-- eslint-disable vue/no-v-text-v-html-on-component -->
    <g-tooltip
      :tooltip-text="props.tooltipText"
      v-html="props.title"
    />
    <!-- eslint-enable vue/no-v-text-v-html-on-component -->
  </div>
</template>

<style lang="scss" scoped>
.app__tooltip {
  font-size: 0.75em;
  margin-bottom: 0.4em;

  @include productBreakpoint('desktop-s') {
    max-width: 400px;
  }

  :deep(.g__tooltip__summary) {
    justify-content: center;

    @include productBreakpoint('desktop-s') {
      justify-content: unset;
    }
  }
}
</style>
