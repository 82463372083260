/* Helpers */
import { priceTemplateBuilder } from '../helpers/mainHelpers';

export default {
  state: {
    priceData: null,
    loadingPrice: true,
  },
  getters: {
    getPriceData: (state) => state.priceData,
    getPrice: (state, getters) => getters.getPriceData?.listPrice || null,
    getFormattedPrice: (state, getters) => getters.getPrice?.formattedPrice || '',
    getShowFormattedPrice: (state, getters) => Boolean(getters.getFormattedPrice?.length),
    getSalePrice: (state, getters) => getters.getPriceData?.salePrice || null,
    getFormattedSalePrice: (state, getters) => getters.getSalePrice?.formattedPrice || '',
    getShowFormattedSalePrice: (state, getters) => Boolean(getters.getFormattedSalePrice?.length),
    getSavings: (state, getters) => getters.getPriceData?.savings || null,
    getFormattedSavings: (state, getters) => getters.getSavings?.formattedPrice || '',
    getShowFormattedSavings: (state, getters) => Boolean(getters.getFormattedSavings?.length),
    getLoadingPrice: (state) => state.loadingPrice,
    getShowPriceData: (state, getters) => getters.getShowPrice && getters.getShowFormattedPrice,
    getShowPriceLoader: (state, getters) => getters.getLoadingPrice && getters.getShowPriceData,
    getDisplayedSalePrice: (state, getters) => (getters.getShowFormattedSalePrice && getters.getFormattedSalePrice
      ? getters.getFormattedSalePrice
      : ''),
    getDisplayedSavings: (state, getters) => (getters.getShowFormattedSavings && getters.getFormattedSavings
      ? `<span class="price-save-text">${getters.getPriceSaveText}</span>${getters.getFormattedSavings}`
      : ''),
  },
  mutations: {
    setPriceData(state, payload) {
      state.priceData = payload;
    },
    setLoadingPrice(state, payload) {
      state.loadingPrice = payload;
    },
  },
  actions: {
    async callPricingProxy({ commit, getters, rootGetters }) {
      commit('setLoadingPrice', true);
      const { getCustomerGroups } = rootGetters;
      const {
        getBuyGarminEndpoint,
        getLocale,
        getCountryCode,
        getPartNumber,
      } = getters;

      const queryParams = new URLSearchParams();
      queryParams.append('locale', getLocale);
      /**
       * If there are multiple customerGroups, they will be divided by a |
       * new URLSearchParams() will convert | to %7C which the pricing APIs expect when there are multiple customer groups
       */
      queryParams.append('customerGroup', getCustomerGroups);

      const locationHref = window?.location?.href;
      if (locationHref.includes('cdncache=false')) queryParams.append('cdncache', false);

      const url = `${getBuyGarminEndpoint}/pricing-proxy-services/countries/${getCountryCode}/skus/${getPartNumber}/price?${queryParams}`;

      try {
        // Using fetch with credentials: 'include' instead of axios
        // axios with the withCredentials: true setting was not correctly sending cookies
        // with the request
        // More info here: https://github.com/axios/axios/issues/2149
        const response = await fetch(url, {
          method: 'get',
          credentials: 'include',
        });

        const priceData = await response.json();

        if (!priceData) {
          commit('setLoadingPrice', false);
          // If no price data, set price back to null
          commit('setPriceData', null);
          return;
        }

        // Generate formatted prices
        if (priceData.listPrice) {
          priceData.listPrice.formattedPrice = priceTemplateBuilder(priceData.listPrice);
        }

        if (priceData.salePrice) {
          priceData.salePrice.formattedPrice = priceTemplateBuilder(priceData.salePrice);
        }

        if (priceData.savings) {
          priceData.savings.formattedPrice = priceTemplateBuilder(priceData.savings);
        }

        commit('setPriceData', priceData);
        commit('setLoadingPrice', false);
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error calling pricing proxy service', error);
        // If no price data due to error, set price back to null
        commit('setPriceData', null);
        commit('setLoadingPrice', false);
      }
    },
  },
};
