export default {
  state: {
    contentfulEnvSettings: {},
    productPageRedesignContentfulFeatureFlag: false,
    affirmEnabled: false,
  },
  getters: {
    getContentfulEnvSettings: (state) => state.contentfulEnvSettings,
    getProductPageRedesignContentfulFeatureFlag: (state) => state.productPageRedesignContentfulFeatureFlag,
    getAffirmEnabled: (state) => state.affirmEnabled,
  },
  mutations: {
    setContentfulEnvSettings: (state, payload) => {
      state.contentfulEnvSettings = payload;
    },
    setProductPageRedesignContentfulFeatureFlag: (state, payload) => {
      state.productPageRedesignContentfulFeatureFlag = payload;
    },
    setAffirmEnabled: (state, payload) => {
      state.affirmEnabled = payload;
    },
  },
  actions: {
    fetchContentfulEnvSettings({ commit }) {
      commit('setContentfulEnvSettings', window?.envSettings || {});
      commit('setProductPageRedesignContentfulFeatureFlag', Boolean(window?.envSettings?.ITFE_PRODUCT_PAGE_REDESIGN));
      commit('setAffirmEnabled', Boolean(window?.envSettings?.ITFE_PRODUCT_PAGE_AFFIRM_ENABLED));
    },
    setContentfulEnvSettings({ commit }, payload) {
      commit('setContentfulEnvSettings', payload);
    },
    setProductPageRedesignContentfulFeatureFlag({ commit }, payload) {
      commit('setProductPageRedesignContentfulFeatureFlag', payload);
    },
    setAffirmEnabled({ commit }, payload) {
      commit('setAffirmEnabled', payload);
    },
  },
};
