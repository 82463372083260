<script setup>
/* Props */
const props = defineProps({
  showKicker: {
    type: Boolean,
    default: false,
  },
  kickerText: {
    type: String,
    default: '',
  },
  kickerTextNoStyling: {
    type: String,
    default: '',
  },
  kickerIsDiscontinued: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <g-kicker
    v-if="props.showKicker"
    class="kicker__container"
    :text="props.kickerTextNoStyling"
    :additional-text="props.kickerText"
    :alt-color="props.kickerIsDiscontinued"
  />
</template>

<style lang="scss" scoped>
.kicker__container {
  margin-bottom: 1rem;
}
</style>
