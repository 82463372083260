<script setup>
/* Props */
const props = defineProps({
  content: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <div class="app__kicker">
    <div
      class="app__product__kicker__flag js__product__kicker__flag"
      v-html="props.content"
    />
    <div
      class="app__product__kicker__additional__text js__product__kicker__additional__text"
      v-html="props.text"
    />
  </div>
</template>

<style lang="scss" scoped>
/* stylelint-disable-next-line color-no-hex */
$kicker-red: #de3333;

.app__kicker {
  min-width: 10%;
  margin-right: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include productBreakpoint('desktop-s') {
    flex-direction: row;
    gap: 0;
  }

  .app__product__kicker__flag {
    margin-right: 0.625rem;
    display: flex;
    box-sizing: border-box;
    justify-content: center;

    :deep(p) {
      font-size: 1em;
      font-weight: 400;
      line-height: 1.6;
      margin: 0 0 16px 0;
      // stylelint-disable-next-line declaration-no-important
      width: auto !important;
    }

    :deep(img) {
      vertical-align: middle;
    }

    :deep(span) {
      padding: 0 1.5em;
    }

    .kicker {
      color: $color-white;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      /* stylelint-disable-next-line */
      line-height: 100%;

      &.pri {
        background-color: $kicker-red;
        padding: 0.7em 1em;
        font-size: 0.65em;
        font-weight: 500;
        letter-spacing: 1px;
      }

      &.deco {

        &.right {
          margin-right: 2em;

          &::after {
            border-top: 1.2em solid transparent;
            border-bottom: 1.2em solid transparent;
            border-left: 1.23em solid transparent;
            right: -1.2em;
          }

          &.pri::after {
            border-left-color: $kicker-red;
          }
        }

        &.left {
          margin-left: 2em;

          &::after {
            border-top: 1.2em solid transparent;
            border-bottom: 1.2em solid transparent;
            border-right: 1.23em solid transparent;
            left: -1.2em;
          }

          &.pri::after {
            border-left-color: $kicker-red;
          }
        }
      }
    }
  }

  .app__product__kicker__additional__text {
    color: $gray-text;
    font-size: 0.8em;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.app__product__kicker--primary .kicker.pri,
.app__product__kicker--secondary {
  font-size: 0.65em;
  font-weight: 500;
  letter-spacing: 1px;
}
</style>
