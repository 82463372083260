<script>
/* Components */
import PriceText from '../components/PriceText.vue';

export default {
  name: 'OldSalesKicker',
  components: {
    PriceText,
  },
  props: {
    savings: {
      type: String,
      default: '',
    },
    saveText: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="app__product__sale__kicker">
    <span class="app__product__sale__kicker__text">{{ saveText }}</span>
    <PriceText
      class="app__product__sale__kicker__savings"
      :formatted-price="savings"
    />
  </div>
</template>

<style lang="scss" scoped>
/* stylelint-disable-next-line color-no-hex */
$kicker-green: #777b00;

.app__product__sale__kicker {
  font-size: 0.65em;
  @include font-primary-weight-medium();
  letter-spacing: 1px;
  color: $color-white;
  line-height: 1.25rem;
  background: $kicker-green;
  display: inline-block;
  margin-bottom: 0;
  padding: 0.7em 1em;
  margin-top: 1em;

  *:last-child {
    margin-bottom: 0;
  }

  &__text {
    font-size: 1.2em;
    letter-spacing: 1px;
    margin-right: 4px;
  }

  &__savings {
    display: inline-block;
    font-size: 1.2em;
  }
}
</style>
