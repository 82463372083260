/* Imports */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { sentry } from './modules/sentry';

// Global PPGN containers registration
import registerPPGNContainers from './modules/registerPPGNContainers';
// Global Components registration
// eslint-disable-next-line no-unused-vars
import * as registerComponents from './modules/registerComponents';

import '@garmin/components-product/dist/style.css';

const app = createApp(App);

app.use(store);
app.use(router);

// Initialize Sentry
sentry(app);
// Initialize global PPGN containers
app.use(registerPPGNContainers);

app.mount('#app');
