/**
 * Helper function to convert a price to cents needed for Affirm
 * @param {number} price - The price to convert to cents
 * @returns {number}     - The price in cents, or null if input is invalid
 * @example
 * toCents(100) // 10000
 * toCents(100.50) // 10050
 * toCents(null) // null
 * toCents(undefined) // null
 */
export const toCents = (price) => (price === null || price === undefined ? null : price * 100);

export default toCents;
